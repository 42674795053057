import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import multiPhone from '../multi_phone.vue'
import exportForm from '../export_form.vue'
import imageUploader from '../image_uploader.vue'

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
    var multilist = document.querySelector("#multi_list");
    var export_form = document.querySelector("#export-form");
    var image_uploader = document.querySelector("#image-uploader");
    var btn_clipboard = document.querySelector(".copy_to_clipboard");

    if(btn_clipboard){
	document.querySelector(".copy_to_clipboard").addEventListener("click", function(){
	    const el = document.createElement('textarea');
	    el.value = location.href;
	    alert("Copied card link to clipboard, now you can share it !");
	    document.body.appendChild(el);
	    el.select();
	    document.execCommand('copy');
	    document.body.removeChild(el);
	});
    }


    if(multilist){
	const app = new Vue({
	    el: multilist,
	    components: {multiPhone}
	});
    }
    if(export_form){
	const exportar_vcards = new Vue({
	    el: export_form,
	    components: {exportForm}
	});
    }
    if(image_uploader){
	const image_input = new Vue({
	    el: image_uploader,
	    components: {imageUploader}
	});
    }
});

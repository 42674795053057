<template>
<div id="multi-phone">
  <fieldset class="fieldset">
    <legend>
      <span>{{legend}} </span>
      <button class="button tiny primary" type="button" @click="add">
	<span class="show-for-sr">Add</span>
	<span>More phone numbers</span>
	<span aria-hidden="true"><i class="fi-plus"></i></span>
      </button>
    </legend>
    <input type="hidden" :name="name" value="" v-if="values.length <= 0">
    
    <!--Multiple phone numbers -->
    <div class="el" v-for="(value, index) in values">
      <div class="input-group">
	<span class="input-group-label">{{index+1}}</span>
	<select :name="alpha2_name" type="text" class="input-group-field" style="max-width: 300px;" v-model="value.alpha2" @change="country_update(index, $event)">
	  <option v-for="(country, index2) in computed_countries" :value="country[0]">
	    <!-- alpha2 and country name -->
	    {{country[1]}} {{country[2]}}
	    <span v-if="country[2] != country[3] && country[3] != '' ">
	      <!-- original name -->
	      ({{country[3]}})
	    </span>
	    </option>
	  </select>
	<span style="margin-right: 2px; margin-left: 2px;">
    <img :src="src" @click="whatsapp_update(value, $event)"/>
    <input type="checkbox"  :name="whatsapp_name" v-model="value.whatsapp" style="display: block;margin: 0 auto;" :checked="value.whatsapp == 'on'"/>
	</span>

	<input :name="phone_name" type="tel" class="input-group-field" v-model="value.phone" @input="update(index, $event)" required="true"  placeholder="Phone number" />


    <input type="hidden" :name="full_phone_name" :value="value.fullPhone">
	<div class="input-group-button">
	  <button class="button primary" type="button" @click="del(index)">
	    <span class="show-for-sr">Remove</span>
	    <span aria-hidden="true"><i class="fi-x"></i></span>
	  </button>
	</div>
      </div>
      
    </div>
  </fieldset>
</div>
</template>
    <script>
    export default {
	props: [ "name", "countries", "numbers", "legend", "src"],
	created: function(){
	    var result = JSON.parse(this.numbers);
	    if(result.length <= 0){
		result.push({phone: "", alpha2: "US", fullPhone: "", whatsapp: "on" });
	    }
	    this.values = result;
	    this.computed_countries = JSON.parse(this.countries);
	},
	data: function() {
	    return {
		values: [],
	        computed_countries: []
	    } 
	},
	methods: {
	    add: function(){
		this.values.push({phone: "", alpha2: "US", fullPhone: "", whatsapp: "on" });
	    },
	    del: function(index){
		this.values.splice(index, 1);
	    },
	    update: function(index, ev){
		
		this.values[index]["phone"] = ev.target.value;
		var country_code = this.get_country_code(this.values[index]['alpha2']);
		this.values[index]["fullPhone"] = country_code+ ev.target.value;
	    },
	    country_update: function(index, ev){
		this.values[index]["alpha2"] = ev.target.value;
		this.values[index]["fullPhone"] = this.get_country_code(this.values[index]["alpha2"]) + this.values[index]['phone']; 
	    },
	    whatsapp_update: function(value, ev){
		var input = ev.target.parentNode.children[1];
		if(value.whatsapp == "on"){
		    value.whatsapp = ""; 
		    input.checked = false;
		}else{
		    value.whatsapp = "on";
		    input.checked = true;
		}
	    },
	    get_country_code: function(alpha2) {
	        var country_code = "";
		for(var country of this.computed_countries){
		    if(alpha2 == country[0]){
			country_code = country[1];
			break;
		    }
		}
		return country_code;
	    }
	},
	computed: {
	    alpha2_name: function(){
	        var custom = this.name;
		return custom.replace("[]", "[][alpha2]")
	    },
	    phone_name: function(){
	        var custom = this.name;
		return custom.replace("[]", "[][phone]")
	    },
	    full_phone_name: function(){
		var custom = this.name;
		return custom.replace("[]","[][fullPhone]")
	    },
	    whatsapp_name: function(){
		var custom = this.name;
		return custom.replace("[]","[][whatsapp]")
	    }
	}
	
    }
</script>
    <style></style>

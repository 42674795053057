<template>
    <div id="input-profile">
      <div class="image-tcard" @click="touchImage">
	<div class="circle-tcard">
    <img id="image-profile" class="profile" :src="url"  />
	</div>
	</div>
   <input  type="file" :name="name" v-show="hide" @change="uploadImage" accept="image/x-png,image/gif,image/jpeg" />
    </div>
</template>
<script>
  export default {
      props: ["src", "name", "base"],
      data: function(){
	  return { 
	      url: "",
	      hide: false ,
	      value: ""
	  };
      },
      created: function(){
	  if(this.src && this.src != ""){
	      this.url = this.src;
	  }else{
	      this.url = this.base;
	  }
      },
      methods: {
	  touchImage: function(e){
var input = document.querySelector("#input-profile").children[1];

	      input.click();
	  },
	  uploadImage: function(e){
              var input = e.target;
              var image = document.querySelector("#image-profile");
	      if(input.files && input.files[0]){
		  var reader = new FileReader();
		  reader.onload = function(ev){
		      image.setAttribute("src", ev.target.result); 
		  }
		  reader.readAsDataURL(input.files[0]);
	      }
	  }
      }
  }

</script>
    <style>
    .input-profile{
	text-align: center;
    }
    .profile:hover{
	cursor: pointer;
    }
</style>

<template>
    <form :action="action" method="post">
    <div>
    <div v-for="error in errors" class="error"> {{error}}</div>
    </div>

    <input type="hidden" name="authenticity_token" :value="token"/>
    <label>From</label>
    <input type="number" v-model="from" name="export[from]" placeholder="From" min='0' :max="totalCount"/>
    <label>To</label>
    <input type="number" v-model="to" name="export[to]" placeholder="To" :min="from" :max="totalCount"/>
    <input type="submit" value="Export to csv" class="button primary expanded"/>
    </form>
</template>
<script>
    export default {
	props: ["totalCount", "action", "token"],
	data: function(){
	    return {
		from: 0,
		to: 0,
		errors: []
	    }
	},
    }
  </script>
<style>
  </style>
